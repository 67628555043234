import { nanoid } from 'nanoid';
import { fetch } from 'whatwg-fetch';
import rebundleData from './../rebundle-data';
import { ONETIME_FREQUENCY, ONETIME_SELLING_PLAN_ID } from './constants';
import { getRCSCheckoutURL } from '../utils/checkout-url';
import {
  findSelectionItemSellingPlanId,
  mapProductVariantsSellingPlans,
  parseVariantAsProduct,
} from '../utils/product';
import { getPlanByFrequencyOptions } from '../utils/plans';
import getBaseUrl from '../utils/get-base-url';
import { ensureArray } from '../utils/array';
import { Logger } from '../plugins/logger';
import { isInitialStateValid } from '../utils/box-config';
import { makeThemeEngineRequest } from '../utils/request';

function getTimestampSecondsFromClient() {
  /**
   * Get the current unix epoch in seconds from the client-side.
   */
  return Math.ceil(Date.now() / 1000);
}

async function getTimestampSecondsFromServer() {
  /**
   * Get the unix epoch from the server instead of using it directly from the
   * client. This must reduce even more the number of invalid Bundles.
   */
  const STORE_FRONT_MANAGER_URL = process.env.STOREFRONT_URL;
  const url = `${STORE_FRONT_MANAGER_URL}/t`;

  try {
    const response = await fetch(url, {
      method: 'get',
      headers: { Accept: 'application/json' },
    });
    if (response.status !== 200) return getTimestampSecondsFromClient();

    const responseJson = await response.json();
    return responseJson.timestamp;
  } catch (ex) {
    Logger.getInstance().error(`Fetch failed: ${ex}. Using client-side date.`);
    return getTimestampSecondsFromClient();
  }
}

export default (i18n) => {
  return {
    setInitialState({ dispatch, commit }, config) {
      commit('setTemplate', config.template);
      dispatch('formatBundleProduct', config.product);
      commit('setShopSettings', config.product.shopSettings ?? {});
      commit('setAppProxy', config.appProxy);
      commit('setBundleDataCorsProxy', config.bundleDataCorsProxy);
      commit('setStorePermissions', config.permissions);
      dispatch('buildSources');
      dispatch('setActiveFilters');
      if (config.customerSubscription) {
        dispatch('setSubscriptionData', config.customerSubscription);
        dispatch('getMissingVariants');
      }
      commit('setBusyState', false);
      commit('setSubscriptionConfig', config.subscriptionConfig);
      commit('setIncentives', config.product.incentives);
      if (isInitialStateValid(config.initialState)) {
        commit('setConfigInitialState', config.initialState);
      }
      commit('setHideSubscriptionForm', !!config.hideSubscriptionForm);
    },

    setSubscriptionData({ commit, getters }, subscription) {
      if (getters.isDynamicBundle && subscription.bundle_selections && !Number(subscription.price)) {
        subscription.price = subscription.bundle_selections.selections.reduce((acc, { price, quantity }) => {
          return acc + price * quantity;
        }, 0);
      }

      commit('setSubscription', subscription);
    },

    formatBundleProduct({ commit }, bundleProduct) {
      const settings = bundleProduct.bundleSettings.settings.settings ?? {};
      Object.keys(bundleProduct.collections)
        .filter((dataSourceId) => {
          const isValid = Array.isArray(bundleProduct.collections[dataSourceId].products);
          if (!isValid) {
            const CollectionHandle = bundleProduct.collections[dataSourceId].handle || '';
            Logger.getInstance().warning(
              `We couldn't read the information from the "${CollectionHandle}" collection. Please make sure this collection is active and available in the online store channel in Shopify.`
            );
          }
          return isValid;
        })
        .forEach((dataSourceId) => {
          const dataSource = bundleProduct.collections[dataSourceId];

          bundleProduct.collections[dataSourceId].products = dataSource.products.map(mapProductVariantsSellingPlans);

          if (settings.display_variants_as_separate_products_in_list) {
            bundleProduct.collections[dataSourceId].products = bundleProduct.collections[dataSourceId].products.reduce(
              (acc, product) => {
                const variantsAsProduct = product.variants.map((variant) =>
                  parseVariantAsProduct(product, variant, {
                    useProductAndVariant: settings.show_product_and_variant_name_in_products_list,
                  })
                );
                return acc.concat(variantsAsProduct);
              },
              []
            );
          }
        });

      commit('setProduct', bundleProduct);
    },

    buildSources({ commit, state }) {
      let sources = {};

      const usedProducts = new Set();

      function isProductAlreadyUsed(productId) {
        const alreadyUsed = usedProducts.has(productId);
        usedProducts.add(productId);
        return alreadyUsed;
      }

      state.product.variants.forEach((bundleVariant) => {
        usedProducts.clear();
        sources[bundleVariant.id] = sources[bundleVariant.id] || {};

        const optionSourcesIds = new Set(bundleVariant.bundleConfig.dataSources.map((s) => s.collectionId));

        Object.keys(state.product.collections)
          .filter((collectionId) => optionSourcesIds.has(collectionId))
          .forEach((dataSourceId) => {
            const dataSource = state.product.collections[dataSourceId];

            sources[bundleVariant.id][dataSourceId] = dataSource.products.filter(
              (product) => !isProductAlreadyUsed(product.id)
            );
          });
      });

      commit('setSources', sources);
    },

    setDefaultOrder({ commit, getters }) {
      let defaultSelection = {};
      const hasSavedOrder = !!(getters.savedOrder && Object.keys(getters.savedOrder).length);
      const preselectedContent = getters.paramsContents;

      getters.dataSources.forEach((dataSource) => {
        const collection = getters.getDataSourceCollection(dataSource);
        collection?.products?.forEach((product) => {
          const { variantId, quantity } = preselectedContent[product.id] ?? {};
          // It should take the first variant because it is the behavior by default the merchant can choose in the admin.
          const firstVariant = product.variants[0];
          const preselectedVariant = product.variants.find(
            (variant) => variant.available && Number(variantId) === Number(variant.id)
          );
          let defaultVariantId = preselectedVariant?.variantId ?? firstVariant?.id ?? product.variants[0].id;

          if (hasSavedOrder) {
            const productVariantIds = product.variants.map((v) => v.id);
            defaultVariantId = productVariantIds.find((id) => getters.savedOrder[id]) ?? defaultVariantId;
          }

          const defaultQty = preselectedVariant ? quantity : 0;
          const savedOrderQty = (getters.savedOrder ?? {})[defaultVariantId]?.qty;

          // Update all the product variants with the selling plan name
          const updatedProduct = mapProductVariantsSellingPlans(product);
          const sellingPlanAllocations =
            updatedProduct.variants.find((variant) => variant.id === defaultVariantId)?.selling_plan_allocations ?? [];
          defaultSelection[product.id] = {
            title: product.title,
            dsId: dataSource.collectionId,
            pId: Number(product.id),
            vId: Number(defaultVariantId),
            sku: '',
            qty: savedOrderQty ?? defaultQty,
            subscriptionData: product.subscriptionData,
            sellingPlanAllocations,
            product: updatedProduct,
          };
        });
      });

      commit('updateSelectedContents', defaultSelection);
    },

    setDefaultRechargePlan({ state, commit, getters, state: { subscription } }) {
      const plan = getters.activeVariant.variant.plans.find(
        ({ id }) => id === Number(state.configInitialState?.planId || 0)
      );

      if (plan) {
        commit('updateSelectedPlan', plan);
        return;
      }

      let frequencyOptions;
      if (subscription.subscription) {
        const { charge_interval_frequency, order_interval_unit, order_interval_frequency } = subscription.subscription;
        frequencyOptions = {
          unit: order_interval_unit,
          orderFrequency: order_interval_frequency ?? ONETIME_FREQUENCY,
          chargeFrequency: charge_interval_frequency ?? ONETIME_FREQUENCY,
        };
      }

      const variantFullPrice = getters.getVariantFullPrice(getters.activeVariant.variant);
      commit(
        'updateSelectedPlan',
        getPlanByFrequencyOptions(getters.activeVariant.variant.plans, frequencyOptions, variantFullPrice)
      );
    },

    setDefaultFrequency({ commit, getters, state, dispatch }) {
      const defaultFrequencyInt = parseInt(getters.productSettings.defaultFrequency);
      const hasDefaultFrequency = Number.isInteger(defaultFrequencyInt);

      if (getters.isShopifySubscription && !state.subscription.subscription) {
        dispatch('setDefaultSellingPlan');
      } else if (state.subscription?.subscription?.status?.toLowerCase() === 'onetime') {
        commit('updateSelectedFrequency', 0);
      } else {
        let shippingFrequency = state.product.subscriptionData['shipping_interval_frequency'];
        // if shippingFrequency is not included then default to 0 for now
        let defaultFrequency =
          state.subscription.subscription?.order_interval_frequency ??
          shippingFrequency?.split(',')[0] ??
          String(ONETIME_SELLING_PLAN_ID);

        // the frequency is not longer 0,1,2 but every 1 day, every 2 days, etc
        if (!state.subscription.subscription && hasDefaultFrequency) {
          defaultFrequency = getters.productSettings.defaultFrequency;
        }

        // If the saved frequency and unit in the subscription option does not match any of the bundle frequency options
        // It adds the saved frequency and unit to the frequency options
        if (!getters.savedFrequencyIsValidOption) {
          const { unit, frequency } = getters.subscriptionDeliveryConfiguration;

          // This statement covers an edge case where the subascription has a different unit than the bundle because
          // the merchant changed bundle configuration
          defaultFrequency = `${frequency}-${unit}`;
        }

        const frequencyOptions = getters.frequencyOptions ?? [];
        if (!frequencyOptions.includes(String(defaultFrequency))) {
          defaultFrequency = frequencyOptions[0];
        }

        commit('updateSelectedFrequency', defaultFrequency);
      }
    },

    setDefaultSellingPlan({ commit, getters }) {
      if (!getters.isShopifySubscription) return;

      const defaultSellingPlanId = parseInt(getters.productSettings.defaultFrequency);

      // Pick the first selling plan different to OneTime
      let sellingPlanAllocation = getters.sellingPlanAllocations.find(
        (s) => s.sellingPlan.id !== ONETIME_SELLING_PLAN_ID
      );

      // For Shopify subscriptions the default frequency can be a selling plan ID or 0 (OneTime).
      if (Number.isInteger(defaultSellingPlanId)) {
        // if defaultFrequency is 0, it means we want oneTime by default
        if (defaultSellingPlanId === ONETIME_SELLING_PLAN_ID && getters.oneTimeAllowed) {
          sellingPlanAllocation = getters.oneTimePlanAllocation;
        } else if (defaultSellingPlanId > ONETIME_SELLING_PLAN_ID) {
          sellingPlanAllocation =
            getters.sellingPlanAllocations.find((s) => s.sellingPlan.id === defaultSellingPlanId) ??
            sellingPlanAllocation;
        }
      }
      commit('updateSelectedSellingPlanAllocation', sellingPlanAllocation);
    },

    updateSelectedSellingPlan({ commit, getters, state }) {
      if (!getters.isShopifySubscription) return;

      // We assumed that frequencies and selling plans have the same order; due to that, we decided to match them using indexes
      const frequencyOptions = state.product.subscriptionDefaults?.order_interval_frequency_options || [];
      const frequencyIndex = frequencyOptions.indexOf(state.selectedFrequency);
      let sellingPlanAllocation = getters.sellingPlanAllocations[frequencyIndex];

      // One time subscription
      if (state.selectedFrequency === String(ONETIME_SELLING_PLAN_ID) && getters.oneTimeAllowed) {
        sellingPlanAllocation = getters.oneTimePlanAllocation;
      }

      // Get a fallback selling plan just for those cases where the selected frquency is not available in the frequency options
      const fallbackSellingPlan = getters.sellingPlanAllocations.find(
        (s) => s.sellingPlan.id !== ONETIME_SELLING_PLAN_ID
      );
      commit('updateSelectedSellingPlanAllocation', sellingPlanAllocation ?? fallbackSellingPlan);
    },

    setDefaultSubscriptionFrequency({ commit, state: { product } }) {
      const frequencies = product.subscriptionDefaults?.order_interval_frequency_options ?? [];
      let defaultFrequency = String(product.subscriptionDefaults?.charge_interval_frequency);

      if (!frequencies.includes(defaultFrequency)) {
        defaultFrequency = frequencies[0] ?? '0';
      }

      commit('updateSelectedFrequency', String(defaultFrequency));
    },

    setActiveFilters({ commit, state }) {
      let productFilters = state.product.filters;

      if (productFilters?.length) {
        let activeFilters = {};
        productFilters.forEach((filter) => {
          let activeFilter = { ...filter };
          // Add value property to keep track of active state
          activeFilter.value = {};
          // Set initial filter active state to false
          filter.options.forEach((option) => (activeFilter.value[option] = false));
          activeFilters[filter.key] = activeFilter;
        });

        commit('updateActiveFilters', activeFilters);
      }
    },

    updateSelectedContents({ commit, state }, payload) {
      let newSelectedContents = JSON.parse(JSON.stringify(state.selectedContents));
      if (payload.id) {
        // Update the selling plans for the new selected variant
        const sellingPlanAllocations =
          newSelectedContents[payload.key].product.variants.find((variant) => variant.id === payload.id)
            ?.selling_plan_allocations ?? [];
        newSelectedContents[payload.key].vId = Number(payload.id);
        newSelectedContents[payload.key].sellingPlanAllocations = sellingPlanAllocations;
      }

      if (typeof payload.quantity === 'number' && payload.quantity >= 0) {
        newSelectedContents[payload.key].qty = payload.quantity;
      }

      commit('updateSelectedContents', newSelectedContents);
    },

    updateAddonsOrder({ commit, state }, payload) {
      let newAddonsOrder = JSON.parse(JSON.stringify(state.addons.addonsOrder));
      if (payload.id) {
        // Update the selling plans for the new selected variant
        const sellingPlanAllocations =
          newAddonsOrder[payload.key].product.variants.find((variant) => String(variant.id) === String(payload.id))
            ?.selling_plan_allocations ?? [];
        newAddonsOrder[payload.key].id = Number(payload.id);
        newAddonsOrder[payload.key].sellingPlanAllocations = sellingPlanAllocations;
      }

      if (typeof payload.quantity === 'number' && payload.quantity >= 0) {
        newAddonsOrder[payload.key].quantity = payload.quantity;
      }
      commit('updateAddonsOrder', newAddonsOrder);
    },

    updateActiveFilters({ commit, state }, payload) {
      let newActiveFilters = JSON.parse(JSON.stringify(state.activeFilters));

      newActiveFilters[payload.key].value[payload.value] = !state.activeFilters[payload.key].value[payload.value];

      commit('updateActiveFilters', newActiveFilters);
    },

    clearAllFilters({ commit, state }, payload) {
      const key = payload?.key ?? false;
      let newActiveFilters = JSON.parse(JSON.stringify(state.activeFilters));

      Object.keys(newActiveFilters).forEach((groupKey) => {
        if (!key || key === groupKey) {
          Object.keys(newActiveFilters[groupKey].value).forEach((filterKey) => {
            newActiveFilters[groupKey].value[filterKey] = false;
          });
        }
      });

      commit('updateActiveFilters', newActiveFilters);
    },

    updateActiveVariant({ commit, getters, state, dispatch }, id) {
      commit('updateActiveVariantId', id);

      if (getters.useRechargePlans) {
        dispatch('setDefaultRechargePlan');
      }

      //Find corresponding selling plan for new variant and update it
      if (getters.isShopifySubscription && state.selectedSellingPlanAllocation) {
        let sellingPlanAllocation = state.selectedSellingPlanAllocation;

        //use variant's default frequency
        if (getters.activeVariantConfig?.defaultFrequency?.length) {
          let defaultSellingPlanIndex = Number(getters.activeVariantConfig.defaultFrequency);
          //if one time is not allowed and default is subscription index, adjust to index 0;
          if (defaultSellingPlanIndex && !getters.oneTimeAllowed) {
            defaultSellingPlanIndex = defaultSellingPlanIndex - 1;
          }
          sellingPlanAllocation = getters.sellingPlanAllocations[defaultSellingPlanIndex];
        } else if (sellingPlanAllocation.sellingPlan.id !== 0) {
          sellingPlanAllocation = getters.sellingPlanAllocations.find((s) => {
            return s.sellingPlan.id === sellingPlanAllocation.sellingPlan.id;
          });
        } else {
          sellingPlanAllocation = getters.oneTimePlanAllocation;
        }

        // Get a fallback selling plan just for those cases where the selected selling plan is not available in the variant selling plans
        sellingPlanAllocation = sellingPlanAllocation ?? getters.getVariantSellingPlanAllocation(getters.activeVariant);
        commit('updateSelectedSellingPlanAllocation', sellingPlanAllocation);
      }
    },

    async addToCart({ dispatch, commit, getters, state }) {
      if (getters.isDynamicBundle) {
        return dispatch('addToCartDynamic', {
          dispatch,
          commit,
          getters,
          state,
        });
      }

      if (!getters.selectionIsBalanced) {
        return false;
      }
      let boxSellingPlan;
      let boxRcProperties;

      let addSellingPlan = getters.isShopifySubscription && state.selectedSellingPlanAllocation.sellingPlan.id !== 0;
      let addRcProperties = !getters.isShopifySubscription && Number(state.selectedFrequency) > 0;

      if (addSellingPlan) {
        boxSellingPlan = state.selectedSellingPlanAllocation.sellingPlan;
      } else if (addRcProperties) {
        let subscriptionData = state.product.subscriptionData;

        boxRcProperties = {
          subscription_id: subscriptionData.subscription_id,
          shipping_interval_unit_type: subscriptionData.shipping_interval_unit_type,
          shipping_interval_frequency: state.selectedFrequency,
        };
      }

      let queue = [];
      function findDiscountedVariantId(item) {
        return item.subscriptionData.original_to_hidden_variant_map[item.id].discount_variant_id;
      }

      let addons = Object.keys(getters.addonsOrderFiltered);
      let parentId;
      if (addons.length > 0) {
        parentId = new Date().getTime();
      }

      addons.forEach((productId) => {
        let item = getters.addonsOrderFiltered[productId];
        let itemData = {
          id: item.id,
          quantity: item.quantity,
          properties: {
            _box_parent: parentId,
          },
        };

        if (addSellingPlan) {
          let itemSellingPlanId = findSelectionItemSellingPlanId(item.sellingPlanAllocations, boxSellingPlan);
          if (itemSellingPlanId) itemData.selling_plan = itemSellingPlanId;
        }

        if (addRcProperties) {
          itemData.properties['shipping_interval_frequency'] = boxRcProperties['shipping_interval_frequency'];
          itemData.properties['shipping_interval_unit_type'] = boxRcProperties['shipping_interval_unit_type'];
          itemData.id = findDiscountedVariantId(item) ?? item.id;
        }

        queue.push(itemData);
      });

      // Add box item
      var boxData = {
        quantity: 1,
        id: getters.activeVariant.id,
        properties: {},
      };

      if (addRcProperties) {
        let discountedActiveVariantData = getters.discountedVariantData(state.product, getters.activeVariant.id);
        boxData.id = discountedActiveVariantData?.discount_variant_id ?? boxData.id;
        boxData.properties = Object.assign({}, boxRcProperties);
      }
      if (addSellingPlan) {
        boxData.selling_plan = boxSellingPlan.id;
      }
      boxData.properties[i18n.t('boxContents')] = getters.selectedContentsString;

      const STORE_FRONT_MANAGER_URL = process.env.STOREFRONT_URL;
      const url = `${STORE_FRONT_MANAGER_URL}/api/v1/bundles`;
      let response = null;

      function sendBundleData(attempt = 0) {
        async function sendStoreFrontBundleData() {
          const timestampSeconds = await getTimestampSecondsFromServer();

          const settings = getters.productSettings.settings.settings;
          const bundleData = rebundleData.encodeLegacyBoxOrder(
            getters.activeVariant.id,
            getters.selectedContentsByVariantId,
            { version: timestampSeconds, isVariantAsProduct: settings.display_variants_as_separate_products_in_list }
          );
          try {
            response = await fetch(url, {
              body: JSON.stringify({
                bundle: bundleData,
              }),
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });
          } catch {
            // Handle NetworkError exceptions
            return sendBundleData(attempt + 1);
          }

          if (!response.ok) {
            return sendBundleData(attempt + 1);
          }

          const res = await response.json();
          if (!res.id) {
            return sendBundleData(attempt + 1);
          }

          return res;
        }

        if (attempt > 0 && attempt < 3) {
          setTimeout(() => {
            return sendStoreFrontBundleData();
          }, 1500);
          return;
        }

        if (attempt >= 3) {
          return { id: null };
        }

        return sendStoreFrontBundleData();
      }

      const bundle = await sendBundleData();
      if (!bundle?.id) {
        Logger.getInstance().error('Max retries to get bundle ID exceeded');
        return;
      }
      boxData.properties._rb_id = bundle.id;

      queue.push(boxData);

      try {
        await dispatch('postToCart', { items: queue });
      } catch (error) {
        commit('setBusyState', true);
        alert(i18n.t('addToCart.error'));
        location.reload();
        return false;
      }
    },

    async addToCartDynamic({ dispatch, commit, getters, state }) {
      if (!getters.selectionIsBalanced) {
        return false;
      }

      const boxRcId = nanoid(9);

      let boxSellingPlan;
      let boxRcProperties;

      let addSellingPlan = getters.isShopifySubscription && state.selectedSellingPlanAllocation.sellingPlan.id !== 0;
      let addRcProperties = !getters.isShopifySubscription && Number(state.selectedFrequency) > 0;

      if (addSellingPlan) {
        boxSellingPlan = state.selectedSellingPlanAllocation.sellingPlan;
      } else if (addRcProperties) {
        let subscriptionData = state.product.subscriptionData;

        boxRcProperties = {
          subscription_id: subscriptionData.subscription_id,
          shipping_interval_unit_type: subscriptionData.shipping_interval_unit_type,
          shipping_interval_frequency: state.selectedFrequency,
        };
      }

      let queue = [];
      function findDiscountedVariantId(item) {
        if (item.id) {
          return item.id;
        }

        const sources = getters.dataSources
          .map((ds) => ds.collection.subscription_data_by_product_id)
          .find((data) => data[item.pId])[item.pId];

        return sources.original_to_hidden_variant_map[item.vId].discount_variant_id;
      }

      let addons = Object.keys(getters.addonsOrderFiltered);
      let parentId;
      if (addons.length > 0) {
        parentId = new Date().getTime();
      }

      addons.forEach((productId) => {
        let item = getters.addonsOrderFiltered[productId];
        let itemData = {
          id: item.id,
          quantity: item.quantity,
          properties: {
            _box_parent: parentId,
          },
        };

        if (addSellingPlan) {
          let itemSellingPlanId = findSelectionItemSellingPlanId(item.sellingPlanAllocations, boxSellingPlan);
          if (itemSellingPlanId) itemData.selling_plan = itemSellingPlanId;
        }

        if (addRcProperties) {
          itemData.properties['shipping_interval_frequency'] = boxRcProperties['shipping_interval_frequency'];
          itemData.properties['shipping_interval_unit_type'] = boxRcProperties['shipping_interval_unit_type'];
          itemData.id = findDiscountedVariantId(item) ?? item.id;
        }

        queue.push(itemData);
      });

      // Add box item
      var boxData = {
        quantity: 1,
        id: getters.activeVariant.id,
        properties: {},
      };

      if (addRcProperties) {
        let discountedActiveVariantData = getters.discountedVariantData(state.product, getters.activeVariant.id);
        boxData.id = discountedActiveVariantData?.discount_variant_id ?? boxData.id;
        boxData.properties = Object.assign({}, boxRcProperties);
      }
      if (addSellingPlan) {
        boxData.selling_plan = boxSellingPlan.id;
      }

      let boxItems = Object.keys(getters.selectedContentsByVariantId);

      boxItems.forEach((variantId) => {
        let item = getters.selectedContentsByVariantId[variantId];
        let itemData = {
          id: variantId,
          quantity: item.qty,
          properties: {},
        };

        itemData.properties['_rc_bundle'] = `${boxRcId}:${state.product.id}`;
        itemData.properties['_rc_bundle_parent'] = state.product.handle;
        itemData.properties['_rc_bundle_variant'] = getters.activeVariant.id;
        itemData.properties['_rc_bundle_collection_id'] = item.dsId;

        if (addSellingPlan) {
          let itemSellingPlanId = findSelectionItemSellingPlanId(item.sellingPlanAllocations, boxSellingPlan);
          if (itemSellingPlanId) itemData.selling_plan = itemSellingPlanId;
        }

        if (addRcProperties) {
          itemData.properties['shipping_interval_frequency'] = boxRcProperties['shipping_interval_frequency'];
          itemData.properties['shipping_interval_unit_type'] = boxRcProperties['shipping_interval_unit_type'];
          itemData.id = findDiscountedVariantId(item) ?? item.id;
        }

        queue.push(itemData);
      });

      try {
        await dispatch('postToCart', { items: queue });
      } catch (error) {
        commit('setBusyState', true);
        alert(i18n.t('addToCart.error'));
        location.reload();
        return false;
      }
    },

    async postToCart({ commit }, data) {
      const postToShopify = (data) => {
        return fetch('/cart/add.js', {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(data),
        });
      };
      let customMethod = window.bundleApp?.postToCart;
      let postToCartMethod = customMethod ?? postToShopify;

      commit('setBusyState', true);

      // The try/catch is necessary because we are hitting the API
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await postToCartMethod(data);
        commit('setBusyState', false);
        if (!customMethod && !response?.ok) {
          throw new Error();
        }
        return response;
      } catch (error) {
        throw error;
      }
    },

    afterApp({ getters, state }) {
      let destination = '/cart';
      const externalFn = window.RechargeBundle?.onAddToCart;
      const callback = getters.productSettings.addToCartCallback ?? {};

      if (typeof externalFn === 'function') {
        try {
          externalFn();
        } catch (error) {
          Logger.getInstance().error(`Exception raised from onAddToCart merchant custom implementation ${error}`);
        }
        return;
      } else if (callback?.type !== 'redirect') {
        window.location.href = destination;
        return;
      }

      // Default redirection for SCI stores and onetime only products
      destination = destination = callback.value;

      // Checkout redirection for RCI stores and non onetimes
      const isOnetime = state.selectedFrequency === String(ONETIME_SELLING_PLAN_ID);
      if (!isOnetime && !getters.isShopifySubscription) {
        destination = getRCSCheckoutURL(getters.productSettings.addToCartCallback);
      }

      window.location.href = destination;
    },

    resetScroll() {
      const appContainer = document.getElementById('build-a-box');

      appContainer.scrollIntoView(true);
    },

    async getMissingVariants({ getters, commit }) {
      if (!getters.subscription?.bundle_selections) return false;

      // Get values from the data sources
      const bundleSelections = getters.subscription.bundle_selections;
      const selectedProductIds = bundleSelections.selections.map((item) => item.external_product_id);

      const { customerToken, customerHash } = await getters.rechargeCustomerData();
      const baseUrl = getBaseUrl({ appProxy: getters.appProxy, customerHash });

      const rechargeUrl = new URL(`${baseUrl}/request_objects`);
      rechargeUrl.searchParams.set('token', customerToken);
      const schemaUrl = rechargeUrl.toString();

      const schema = { products: { shopify_product_id: selectedProductIds.join(','), published_status: 'any' } };
      const response = await makeThemeEngineRequest(`${schemaUrl}&schema=${JSON.stringify(schema)}`);
      const responseJson = await response.json();

      const missingVariants = {};
      /* As the Theme Engine parses the result as an object or array depending on the amount of data.
      We must ensure we have an array to be able to iterate the result.
      For example, if we request two product IDs = 1,2 and the TE retrieves both, the response is
      {"products": [{"id": 1, ..}, {"id": 2, ...}]}. On the other hand, if the TE only retrieves a single product,
      the response body is {"products": {"id": 1, ...}} */
      for (const product of ensureArray(responseJson.products)) {
        const { variants, ...productRest } = product.shopify_details;
        for (const variant of variants) {
          missingVariants[variant.shopify_id] = {
            ...variant,
            featured_image: variant.image,
            product: { ...productRest, featured_image: productRest.image?.src || null },
          };
        }
      }

      commit('setMissingVariants', { missingVariants });
    },
  };
};
